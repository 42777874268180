html,
body {
  overflow-x: hidden !important;
}

::-moz-selection {
  /* Code for Firefox */
  color: blue;
  background: yellow;
}

::selection {
  color: blue;
  background: yellow;
}

.container {
  margin-top: auto;
}

.navbar {
  background-color: gold !important;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.3s;
}

.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff !important;
}

.nav-link {
  color: #19149b !important;
  font-size: 1.5em;
  margin: 25px;
  text-align: center;
  text-decoration: none !important;
}

.nav-link:hover {
  color: #fff !important;
}

.nav-separator,
.nav-separator:hover {
  color: black !important;
  margin-left: 5px;
  margin-right: 5px;
}

.dropdown-menu {
  background-color: #c8c8c8 !important;
}

.dropdown-item {
  color: #8282b4 !important;
  background-color: #c8c8c8 !important;
  font-size: 1.5em;
}

.dropdown-item:hover {
  color: #fff !important;
  background-color: #ddd !important;
}

.car {
  width: 100px;
  height: 100px;
  background-image: url("images/car.png");
  background-repeat: no-repeat;
  background-size: 80px 35px;
  position: relative;
  animation-name: car;
  animation-duration: 20s;
  animation-iteration-count: 1;
  animation-delay: 2s;
}

@keyframes car {
  0% {
    left: 0px;
    top: 0px;
  }

  25% {
    left: 110%;
    top: 0px;
  }

  75% {
    left: 0%;
    top: 0px;
  }

  100% {
    left: 0%;
    top: 0px;
  }
}

.title-anim {
  width: 100%;
  position: absolute;
  text-align: center;
  margin: auto;
  top: 55%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
}

#cssanim {
  display: none;
}

.cssanimation {
  font-size: 7em;
  letter-spacing: -4px;
  font-weight: 400;
  color: white;
  text-align: center;
  margin-top: 3vh;
  text-shadow: 1px 2px #aaa, 5px 8px 8px #ddd;
}

.cssanimation:hover {
  color: lightgray;
  text-shadow: 1px 2px #eee, 5px 8px 8px #fff;
}

.cssanimation,
.cssanimation span {
  animation-duration: 0.25s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-delay: 2s;
}

.cssanimation span {
  display: inline-block;
}

.leFlyInBottom span {
  animation-name: leFlyInBottom;
}

@keyframes leFlyInBottom {
  0% {
    transform: translate(0px, 80px);
    opacity: 0;
  }

  50% {
    transform: translate(10px, -50px);
    animation-timing-function: ease-in-out;
  }
}

#hero {
  margin-top: 150px;
  object-fit: cover;
  padding-top: 20px;
  background: url("images/honda.jpg") 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-left: 30vw;
  padding-right: 30vw;
  height: 100vh;
}

.home {
  background-color: #ddd;
}

main {
  margin-top: -400px;
  position: relative;
  width: 100% !important;
  padding: 0px !important;
}

.banner {
  background-color: #19149b;
  margin: auto;
  padding: 0 50px 50px 50px;
  border: 5px solid grey;
  border-width: 0 8vw 0 8vw;
  box-shadow: 5px 5px 5px #666;
  color: white;
  width: 99.55vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-top: 0px !important;
}

.banner p {
  font-size: 1.5em;
  margin-top: 10px;
  text-align: center;
  border: 1px solid dimgray;
  box-shadow: 5px 5px 5px #666;
  color: yellow;
  opacity: 0.75;
  background-color: #191464;
}

.banner i {
  margin: 10px;
  vertical-align: sub;
  opacity: 0.75;
}

.banner img {
  opacity: 0.75;
  margin-top: 15px;
}

.banner img:hover {
  opacity: 1;
}

.banner i:hover {
  color: yellow;
  opacity: 1;
  text-shadow: 5px 5px #000;
}

.banner p:hover {
  color: yellow;
  border-color: yellow;
  background-color: #1914c8;
  box-shadow: 5px 5px 15px #222;
  opacity: 1;
  text-shadow: 5px 5px #232323;
}

.container {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  padding-bottom: 20px;
  max-width: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  border-width: 0 8vw 0 8vw;
  border-style: solid;
  border-color: #ddd;
  padding: 0 50px 50px 50px;
}

.row {
  margin-right: 0px !important;
}

h1 {
  padding: 15px;
}

p {
  text-align: left;
  padding: 0 50px;
}

.section:target::before {
  content: "";
  display: block;
  padding-top: 50px;
  margin-top: -50px;
}

.sign {
  padding: 15px;
  filter: drop-shadow(2px 2px 15px #666);
  opacity: 0.75;
  margin-right: 30px;
}

.sign:hover {
  opacity: 1;
}

.btn {
  font-size: 3.5rem;
  border-radius: 15px;
  margin: 20px;
  margin-bottom: 50px;
  box-shadow: 5px 5px 5px #ccc;
  opacity: 0.75;
  background-color: #19149b;
  position: relative;
}

.btn:hover {
  opacity: 1;
}

.prices {
  border: 5px solid lightgray;
  border-radius: 10px;
  border-collapse: separate;
  width: 50%;
  margin: 0 50px;
  box-shadow: 5px 5px 15px #ccc;
  float: left;
  margin-bottom: 25px;
}

.packages {
  border: 5px solid lightgray;
  border-radius: 10px;
  border-collapse: separate;
  width: 20%;
  margin: 0px 10px;
  box-shadow: 5px 5px 15px #ccc;
  margin-bottom: 25px;
  display: none;
}

.package-head {
  display: none;
}

.price-table {
  border: 5px solid lightgray;
  border-radius: 10px;
  border-collapse: separate;
  width: 100%;
  margin: 0px -50px;
  box-shadow: 5px 5px 15px #ccc;
  margin-bottom: 50px;
  display: inline-table;
}

.price-table tr:nth-child(odd),
.packages tr:nth-child(odd) {
  background-color: lightskyblue;
}

.price-table tr:nth-child(even),
.packages tr:nth-child(even) {
  background-color: rgba(0, 123, 255, 0.25);
}

.table-hover tbody tr:hover td {
  background: #19149b;
  color: white;
}

.price-table tr:nth-child(1) td:hover {
  background-image: linear-gradient(to right, gold, rgba(255, 255, 0, 1), gold);
  color: #19149b;
}

.price-table tr:nth-child(1) td {
  background-image: linear-gradient(to right, gold, rgba(255, 255, 0, 1), gold);
}

.prices tr:nth-child(6) td:hover {
  background-image: linear-gradient(to right, gold, rgba(255, 255, 0, 1), gold);
}

.price-table .btn,
.packages .btn {
  font-size: 1.5rem;
  border-radius: 15px;
  margin: 10px;
  margin-bottom: 10px;
  box-shadow: 5px 5px 5px #ccc;
  opacity: 0.75;
  background-color: #19149b;
}

.price-table .btn:hover,
.packages .btn:hover {
  opacity: 1;
  background-color: rgba(0, 123, 255, 0.25);
}

.hours {
  font-size: 1em !important;
  border: none;
  border-radius: 0px;
  border-collapse: collapse;
  width: 100%;
  margin: 0px;
  box-shadow: 0px;
  float: none;
  display: inline;
  margin-bottom: 0px;
}

.hours th {
  text-align: center;
  font-size: 1.5em;
  background-color: none;
}

.hours td {
  text-align: left;
  font-size: 1em;
  border-radius: 0px;
}

th,
.tabhead {
  text-align: center;
  font-size: 3em;
  background-image: linear-gradient(to right, gold, rgba(255, 255, 0, 1), gold);
  color: #19149b;
}

th:hover {
  background-color: transparent;
}

td {
  text-align: center;
  font-size: 2.5em;
  border-radius: 10px;
  position: relative;
}

tr {
  position: relative;
}

#discount {
  color: red;
}

#price {
  font-weight: 600;
}

.text-center {
  border-width: 0 0 0 0;
  border-style: solid;
  border-color: #ddd;
  margin-top: 0px;
}

.map {
  float: left;
  margin-left: 80px;
  border: 2px solid gray;
}

i {
  padding-right: 5px;
}

.contact {
  padding-top: 100px;
}

a {
  color: #19149b !important;
  text-decoration: underline;
}

a:hover {
  color: #007bff !important;
}

.btn:after,
tr:nth-child(1) td:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 0%;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
  border-radius: 15px;
}

tr:nth-child(1) td:after {
  height: 580%;
}

.btn:after {
  height: 98%;
}

.btn:hover:after,
tr:nth-child(1) td:hover:after {
  width: 120%;
  background-color: rgba(255, 255, 255, 0);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  border-radius: 15px;
}

@media (min-width: 1500px) {
  .dropdown-item,
  .nav-link {
    font-size: 1.4em !important;
  }

  #ul {
    margin-left: 0px !important;
  }

  .price-table td,
  .price-table h1 {
    font-size: 2rem !important;
  }

  .price-table .btn {
    font-size: 1.5rem !important;
    margin: 10px !important;
    padding: 8px 5px !important;
  }
}

@media (min-width: 1000px) {
  .banner {
    padding: 0 100px 50px 100px;
  }
}

@media (min-width: 1200px) {
  .banner {
    padding: 0 300px 50px 300px;
  }

  .dropdown-item,
  .nav-link {
    font-size: 1em;
  }

  #ul {
    margin-left: -80px;
  }

  .price-table td,
  .price-table h1 {
    font-size: 1rem;
  }

  .price-table .btn {
    font-size: 1rem;
    padding: 5px;
    margin: 5px;
  }
}

@media (max-width: 1200px) {
  .dropdown-item,
  .nav-link {
    font-size: 0.85em;
    margin: 5px;
  }

  .navbar {
    padding: 8px !important;
  }

  #logo {
    height: 120px !important;
  }

  #hero {
    margin-top: 100px;
  }

  .cssanimation {
    font-size: 6em !important;
  }

  .price-table td,
  .price-table h1 {
    font-size: 1.2rem;
  }

  .price-table td,
  .price-table th {
    padding: 0.5rem !important;
  }

  .price-table .btn {
    font-size: 0.8rem;
    margin: 8px;
    padding: 5px;
  }

  .map {
    margin: 0px !important;
    float: none !important;
  }
}

@media (max-width: 1000px) {
  main {
    margin-top: -50px;
  }

  .banner {
    padding: 0 80px 50px 80px;
  }

  .dropdown-item,
  .nav-link {
    font-size: 0.75em;
  }

  .navbar {
    padding: 8px !important;
    max-height: 100px !important;
  }

  th,
  td {
    font-size: 1.5em !important;
  }

  #car {
    display: none !important;
  }

  #logo {
    height: 100px !important;
  }

  #hero {
    margin-top: 75px;
  }

  .cssanimation {
    font-size: 5em !important;
  }

  .packages {
    width: 40%;
    display: inline-table;
  }

  .package-head {
    display: block;
  }

  .price-table {
    display: none;
  }

  #hero {
    padding-left: 0vw;
    padding-right: 0vw;
  }
}

@media (max-width: 767px) {
  .nav-separator,
  .nav-separator:hover {
    display: none !important;
  }

  .navbar {
    padding: 0px 8px !important;
    max-height: 400px !important;
  }

  #car {
    display: none !important;
  }

  #logo {
    height: 65px !important;
  }

  #hero {
    margin-top: 50px;
    height: 30vh;
  }

  .cssanimation {
    font-size: 3em !important;
    /* display: none; */
  }

  .title-anim {
    top: 20% !important;
    /* display: none; */
  }

  .container {
    padding: 0px !important;
  }

  p {
    padding: 0 20px 0 40px !important;
  }

  .banner p {
    font-size: 1em;
  }

  .banner i {
    margin: 5px;
    vertical-align: sub;
  }

  .banner {
    padding: 0 30px 30px 30px;
  }

  .sign {
    height: 120px !important;
    padding: 5px;
  }

  .prices {
    width: 80% !important;
  }

  .map {
    width: 80%;
    height: 40vh;
    margin: 40px !important;
  }

  iframe {
    width: 90%;
  }

  li {
    padding-right: 40px;
  }

  .hours td {
    font-size: 1em !important;
  }

  .hours {
    display: inline-table;
  }

  .contact {
    padding-top: 20px;
  }

  .packages {
    width: 80%;
  }

  #ul {
    width: 100% !important;
  }
}

.lightboxcontainer {
  width: 100%;
  text-align: left;
}

.lightboxleft {
  width: 40%;
  float: left;
}

.lightboxright {
  width: 60%;
  float: left;
}

.lightboxright iframe {
  min-height: 390px;
}

.divtext {
  margin: 36px;
}

@media (max-width: 800px) {
  .lightboxleft {
    width: 100%;
  }

  .lightboxright {
    width: 100%;
  }

  .divtext {
    margin: 12px;
  }
}
